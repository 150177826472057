import { useEffect, useState } from 'react'
import { apiGetOne, apiUpdate } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { CoreForm } from '../../components/CoreForm'
import { Button, Flex, Form, Input, message, Skeleton, Tag } from 'antd'
import { WechatOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import type { DescriptionsProps } from 'antd'
import { apiMe } from '../../api/user'
import { useAppSelector } from '../../hooks/useAppSelector'
import { FormatFormInput } from '../../components/FormatFormInput'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setUser } from '../../store/slices/user'

const Profile = () => {
  const [loading, setLoading] = useState(true)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [items, setItems] = useState<DescriptionsProps['items']>([])
  const [active, setAcive] = useState(false)
  const [role, setRole] = useState('user')
  const [avatar, setAvatar] = useState('')
  const user = useAppSelector(state => state.user.data)
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (user) {
      setAcive(user?.is_active || false)
      setRole(user?.role || 'user')
      setAvatar(user?.avatar || '')
      const _items: DescriptionsProps['items'] = [
        {
          label: 'Фамилия',
          span: 3,
          children: <FormatField value={user?.lastname} type='text' />
        },
        {
          label: 'Имя',
          span: 3,
          children: <FormatField value={user?.firstname} type='text' />
        },
        {
          label: 'Баланс',
          span: 3,
          children: <FormatField value={user?.balance} type='money' />
        },
        {
          label: 'Электронная почта',
          span: 3,
          children: <FormatField value={user?.email} type='email' />
        },

        {
          label: 'Номер телефона',
          span: 3,
          children: <FormatField value={user?.phone} type='phone' />
        },
        {
          label: 'Телеграмм',
          span: 3,
          children: <FormatField value={user?.telegram} type='telegram' />
        }
      ]
      setItems(_items)
    }
  }, [])

  const handleSave = async (values: any) => {
    console.log(values)
    if (user?.id) {
      const result = await apiUpdate('user/me/', undefined, {
        phone: values.phone,
        telegram: values.telegram,
        lastname: values.lastname,
        firstname: values.firstname
      })
      if (result.isError) {
        messageApi.error('Произошла ошибка при изменении пользователя')
      } else {
        messageApi.success('Изменения успешно сохранены')
        dispatch(setUser(result.data as Api.Response.User))
      }
    }
  }
  return (
    <>
      {contextHolder}

      {items && (
        <CoreForm title=''>
          <Button
            type='link'
            onClick={() => navigate(-1)}
            style={{ marginBottom: '16px', paddingLeft: '0px', marginTop: '0px' }}
          >
            <ArrowLeftOutlined /> Назад {searchParams.get('path') ? 'к переводу' : ''}
          </Button>
          <Flex align='start' justify='space-between' style={{ width: '100%' }}>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSave}
              style={{
                flex: 1,
                maxWidth: '600px'
              }}
              initialValues={{
                email: user?.email,
                lastname: user?.lastname,
                firstname: user?.firstname,
                phone: user?.phone,
                telegram: user?.telegram
              }}
            >
              <FormatFormInput label='Электронная почта' name='email' typeData='text' readonly value={user?.email} />
              <FormatFormInput label='Фамилия' name='lastname' value={user?.lastname} />
              <FormatFormInput label='Имя' name='firstname' value={user?.firstname} />
              <FormatFormInput label='Номер телефона' name='phone' value={user?.phone} />
              <Form.Item label='Телеграмм' name='telegram'>
                <Input
                  addonBefore='@'
                  onChange={e => {
                    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                    form.setFieldsValue({ telegram: value })
                  }}
                />
              </Form.Item>

              <small style={{ color: 'gray', display: 'block', marginTop: '-22px' }}>
                Разрешите боту отправлять вам сообщения, что бы получать уведомления в{' '}
                <a href='https://t.me/voicecover_noty_bot' target='_blank' rel='noreferrer'>
                  Telegram <WechatOutlined />
                </a>
              </small>
              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ marginTop: '16px' }}>
                  Сохранить изменения
                </Button>
              </Form.Item>
            </Form>

            <Flex vertical gap={16} style={{ minWidth: '200px', alignItems: 'center' }}>
              {avatar ? (
                <img
                  src={avatar}
                  alt='Аватар пользователя'
                  style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }}
                />
              ) : (
                <Skeleton.Image />
              )}
              <Flex gap={8}>
                {active ? <Tag color='green'>Активен</Tag> : <Tag color='red'>Неактивен</Tag>}
                <FormatField value={role} type='role' />
              </Flex>
            </Flex>
          </Flex>
        </CoreForm>
      )}
    </>
  )
}

export default Profile
