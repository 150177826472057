import { Form, Input, Switch, Radio, Typography, InputNumber, DatePicker, TimePicker } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { FormatField } from '../FormatField'
import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'

const FormatFormInput = ({
  label,
  name,
  typeData,
  value,
  readonly,
  addonBefore
}: {
  label: string
  name: string
  typeData?: string
  value?: any
  readonly?: boolean
  addonBefore?: string
}) => {
  switch (typeData) {
    case 'boolean':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='boolean' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name} valuePropName='checked'>
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} size='small' />
        </Form.Item>
      )
    case 'password':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value='***' type='text' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name}>
          <Input.Password />
        </Form.Item>
      )
    case 'role':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='role' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name}>
          <Radio.Group>
            <Radio.Button value='admin'>Админ</Radio.Button>
            <Radio.Button value='manager'>Менеджер</Radio.Button>
            <Radio.Button value='user'>Пользователь</Radio.Button>
          </Radio.Group>
        </Form.Item>
      )
    case 'money':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='money' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name}>
          <Typography.Text>
            <FormatField value={value} type={'money'} />
          </Typography.Text>
        </Form.Item>
      )
    case 'number':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='number' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name}>
          <InputNumber />
        </Form.Item>
      )
    case 'datetime':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='datetime' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name} valuePropName='dateString'>
          <DatePicker locale={locale} showTime format={'DD.MM.YYYY hh:mm:ss'} />
        </Form.Item>
      )
    case 'date':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='date' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name} valuePropName='dateString' rules={[{ required: false }]}>
          <DatePicker locale={locale} format={'DD.MM.YYYY'} />
        </Form.Item>
      )
    case 'time':
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='time' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name} valuePropName='dateString'>
          <TimePicker locale={locale} format={'hh:mm:ss'} />
        </Form.Item>
      )
    default:
      return readonly ? (
        <Form.Item label={label} name={name}>
          <FormatField value={value} type='text' />
        </Form.Item>
      ) : (
        <Form.Item label={label} name={name}>
          <Input addonBefore={addonBefore} />
        </Form.Item>
      )
  }
}

export default FormatFormInput
