import React from 'react'
import { Card, Col, Dropdown, Flex, Row, Spin, message } from 'antd'
import preview from '../../../assets/stab.png'
import type { MenuProps } from 'antd'
import { Rating } from '../../../components/Rating'
const SuccessVideo = ({ info }: { info: Api.Response.VideoTranslate }) => {
  const [loading, setLoading] = React.useState(false)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [videoInfo, setVideoInfo] = React.useState<Partial<Api.Response.VideoTranslate>>({
    video_origin: '',
    video_translate: '',
    sub_origin: '',
    sub_translate: ''
  })
  const items = [
    {
      key: '1',
      label: 'Скачать субтитры (перевод)'
    },
    {
      key: '2',
      label: 'Скачать субтитры (оригинал)'
    },
    {
      key: '3',
      label: 'Скачать видео (оригинал)'
    }
  ]
  const openLink = (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank')
    } else {
      messageApi.error('Ссылка недоступна')
    }
  }
  const onMenuClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case '1':
        openLink(videoInfo.sub_translate)
        break
      case '2':
        openLink(videoInfo.sub_origin)
        break
      case '3':
        openLink(videoInfo.video_origin)
        break
    }
  }
  React.useEffect(() => {
    setVideoInfo({
      video_origin: info.video_origin,
      video_translate: info.video_translate,
      sub_origin: info.sub_origin,
      sub_translate: info.sub_translate,
      preview: info.preview
    })
  }, [info])
  return (
    <>
      {contextHolder}
      <Row>
        <Col md={24}>
          <Flex align='center' justify='center' gap={20} style={{ paddingTop: '40px' }}>
            <Card title='Обработка видео завершилась' style={{ width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <Spin delay={500} spinning={videoInfo?.video_translate === ''}>
                  <video
                    poster={videoInfo.preview ? videoInfo.preview : preview}
                    controls
                    className='video-preview'
                    src={videoInfo?.video_translate}
                  />
                </Spin>

                <Flex align='center' justify='center' gap={10} vertical>
                  <Spin spinning={loading} delay={500}>
                    <Dropdown.Button
                      menu={{ items, onClick: onMenuClick }}
                      size='large'
                      type='primary'
                      onClick={() =>
                        videoInfo.video_translate
                          ? openLink(videoInfo.video_translate)
                          : openLink(videoInfo.video_origin)
                      }
                    >
                      Скачать видео
                    </Dropdown.Button>
                  </Spin>
                  {loading && <p>Файл подготавливается...</p>}
                </Flex>
              </div>
            </Card>
          </Flex>
        </Col>
        <Col md={24}>
          <Flex justify='center' style={{ marginTop: '25px', flexDirection: 'column' }}>
            <Rating id={info.id} />
          </Flex>
        </Col>
      </Row>
    </>
  )
}
export default SuccessVideo
