import React from 'react'
import { Card, Dropdown, Space, Spin, message } from 'antd'
import stop from './../../../assets/stop.svg'
import { apiCreate, apiDelete, apiGetBlob, apiGetOne } from '../../../api/crud'
import { redirect, useNavigate } from 'react-router-dom'
import {
  MenuUnfoldOutlined,
  CloudDownloadOutlined,
  SyncOutlined,
  DeleteOutlined,
  LoadingOutlined,
  BranchesOutlined
} from '@ant-design/icons'
import error from '../../../assets/nophoto.jpg'
import { DeleteConfirm } from '../../../components/modals/DeleteConfirm'
import { MenuProps } from 'antd/lib'
import fileDownload from 'js-file-download'
type MenuItem = {
  label?: React.ReactNode
  key: string
  icon?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  condition: (status: string) => boolean
  type?: 'divider'
}
const Video = ({ item, updateListTrigger }: { item: Api.Response.VideoTranslate; updateListTrigger: () => void }) => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [status, setStatus] = React.useState<string>('')
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [deleteWaitng, setDeleteWaitnig] = React.useState(false)
  const [downloadWaiting, setDownloadWaitnig] = React.useState(false)
  const [copyWaiting, setCopyWaitnig] = React.useState(false)
  const nav = useNavigate()
  const [img, setImg] = React.useState<String>(item?.preview)
  const abortControllerPreview = new AbortController()
  const abortControllerStatus = new AbortController()

  const menuItems: MenuItem[] = [
    {
      label: 'Скачать',
      key: 'download',
      icon: <CloudDownloadOutlined />,
      onClick: e => {
        e.stopPropagation()
        e.preventDefault()
        if (item.video_translate) {
          nav(item.video_translate)
        } else {
          messageApi.error('Видео не доступно для скачивания')
        }
      },
      condition: status => status === 'Завершено'
    },
    {
      label: 'Перезапустить перевод',
      key: 'restart',
      icon: <SyncOutlined spin />,
      onClick: e => {
        e.stopPropagation()
        e.preventDefault()

        nav(`/app/add/${item.id}/?restart=true`)
      },
      condition: status => ['Ошибка', 'Неизвестно'].includes(status)
    },
    {
      label: 'Перевести на другой язык',
      key: 'translate',
      icon: <BranchesOutlined />,
      onClick: e => {
        e.stopPropagation()
        e.preventDefault()

        copyTask()
      },
      condition: () => status === 'Завершено'
    },
    {
      key: 'divider1',
      type: 'divider',
      condition: () => true
    },
    {
      label: 'Удалить видео',
      key: 'delete',
      icon: <DeleteOutlined />,
      onClick: e => {
        e.stopPropagation()
        setOpenDeleteConfirm(true)
      },
      condition: () => true
    }
  ]

  const filteredItems = menuItems.filter(item => item.condition(status))

  const items: MenuProps['items'] = filteredItems.map(item => {
    if (item.type === 'divider') {
      return { type: 'divider' }
    }
    return {
      label: (
        <div
          onClick={e => {
            e.stopPropagation()
            item.onClick && item.onClick(e as any)
          }}
        >
          {item.icon} {item.label}
          {item.key === 'download' && downloadWaiting && <LoadingOutlined spin style={{ marginLeft: '5px' }} />}
          {item.key === 'translate' && copyWaiting && <LoadingOutlined spin style={{ marginLeft: '5px' }} />}
        </div>
      ),
      key: item.key,
      disabled: item.disabled
    }
  })

  const getStatus = async () => {
    try {
      const req = await apiGetOne(`translate/${item.id}/status/`, undefined, abortControllerStatus.signal)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      setStatus(req.data.status)
    } catch (err) {
      console.error(err)
    }
  }

  // const getPreview = async () => {
  //   const timeoutDuration = 8000 // 5 секунд

  //   const fetchPreview = async () => {
  //     try {
  //       const req = await apiGetBlob(`translate/${item.id}/download/preview/`, abortControllerPreview.signal)
  //       if (req.isError) {
  //         setImg(error)
  //         throw new Error(req.data.detail.toString())
  //       }
  //       //@ts-ignore
  //       setImg(URL.createObjectURL(req.blob))
  //     } catch (err: any) {
  //       if (err.name === 'AbortError') {
  //         // console.log('Запрос превью отменен')
  //       } else {
  //         console.error(err)
  //       }
  //       setImg(error)
  //     }
  //   }

  //   const timeout = new Promise((_, reject) => {
  //     setTimeout(() => {
  //       abortControllerPreview.abort()
  //       reject(new Error('Превью не получено в течение 8 секунд'))
  //     }, timeoutDuration)
  //   })

  //   try {
  //     await Promise.race([fetchPreview(), timeout])
  //   } catch (err) {
  //     console.error(err)
  //     setImg(error)
  //   }
  // }
  // const downloadResult = async () => {
  //   setDownloadWaitnig(true)
  //   try {
  //     const req = await apiGetBlob(`translate/${item.id}/download/video_origin/`)
  //     if (req.isError) {
  //       throw new Error(req.data.detail.toString())
  //     }
  //     //@ts-ignore
  //     fileDownload(req.blob, `${item.video.name}.mp4`)
  //   } catch (err) {
  //     console.error(err)
  //     messageApi.error('Произошла ошибка')
  //   }
  //   setDownloadWaitnig(false)
  // }
  const deleteItem = async () => {
    try {
      setDeleteWaitnig(true)
      const del = await apiDelete(`translate`, item.id)
      if (del.isError) {
        throw new Error(del.data.detail.toString())
      }
      setOpenDeleteConfirm(!openDeleteConfirm)
      updateListTrigger()
      messageApi.success('Запись удалена')
    } catch (err) {
      console.error(err)
      messageApi.error('Ошибка удаления')
      setOpenDeleteConfirm(!openDeleteConfirm)
    } finally {
      setDeleteWaitnig(false)
    }
  }
  const copyTask = async () => {
    try {
      setCopyWaitnig(true)
      // Отменяем текущие запросы
      abortControllerPreview.abort()
      const req = await apiCreate<Api.Response.VideoTranslate>(`translate/${item.id}/copy`, {})
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      messageApi.success('Создали копию видео для перевода на новый язык')
      req.data.id && nav(`/app/add/${req.data.id}/`)
      setCopyWaitnig(false)
    } catch (err) {
      console.error(err)
    }
  }
  React.useEffect(() => {
    getStatus()
    // getPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      // abortControllerPreview.abort()
      abortControllerStatus.abort()
    }
  }, [])
  const { Meta } = Card
  return (
    <div className='video-card'>
      {contextHolder}
      <DeleteConfirm
        isOpen={openDeleteConfirm}
        onOk={() => deleteItem()}
        onCancel={() => setOpenDeleteConfirm(!openDeleteConfirm)}
        loading={deleteWaitng}
      />
      <Card
        // title={item.video.name}
        bordered={false}
        hoverable
        style={{ textAlign: 'center' }}
        cover={
          img ? (
            <img
              src={img ? img : stop}
              alt=''
              style={{ maxWidth: '100%', objectFit: 'cover', aspectRatio: '1/1', width: '100%', height: '100%' }}
            />
          ) : (
            <div
              style={{
                aspectRatio: '1/1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin size='large' />
            </div>
          )
        }
        actions={[
          <Dropdown menu={{ items }} trigger={['click']} className='dropdown-fix'>
            <MenuUnfoldOutlined
              key='setting'
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            />
          </Dropdown>
        ]}
        onClick={e => {
          if (e.defaultPrevented) return
          e.stopPropagation()
          e.preventDefault()
          nav(`/app/add/${item.id}`)
        }}
      >
        <Meta
          title={item.video.name}
          description={status ? status : 'Загрузка статуса...'}
          style={{ marginTop: '10px' }}
        />
      </Card>
    </div>
  )
}
export default Video
